import { Component, OnInit } from '@angular/core';
import { NewTableData } from 'src/app/components/new-table/new-table.component';
import { SurveysService } from 'src/app/services/surveys.service';
import { AddSurveyComponent } from './add-survey/add-survey.component';
import { MatDialog } from '@angular/material/dialog';
import { AvatarDataCell } from 'src/app/components/new-table/new-table.component';

import {
  GeneralDataCell, HyperlinkCell, StatusDataCell,
} from 'src/app/components/new-table/table-cell/table-cell.component';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: [ './surveys.component.scss' ],
})
export class SurveysComponent implements OnInit {
  isLoading = false;
  searchText = '';
  activeFlag: 1;
  mode = 'active';
  tableData: NewTableData = {
    columnTitles: [
      {
        name: 'Survey Title & ID',
        sortable: true,
        sortByProp: 'name',
        isDefaultSort: true,
      },
      {
        name: 'URL',
        sortable: true,
        sortDirection: 'ASC',
        sortByProp: 'url',
      },
      {
        name: 'Added By',
        sortable: true,
        sortByProp: 'contactFullname',
      },
      {
        name: 'Status',
        sortable: true,
        sortByProp: 'activeFlag',
      },
    ],
    selectable: false,
    data: [],
    noDataFoundMessage: 'No surveys found',
    rowStyling: 'default',
    title: 'Surveys',
    burgerContent: [
      {
        content: 'View Details',
        eventName: 'view-details',
      },
      {
        content: 'Edit Survey',
        eventName: 'edit-survey',
      },
      {
        content: 'Remove Survey',
        eventName: 'remove-survey',
      },
    ],
    hasBurgerMenu: true,
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemsPerPage: 0,
      curPage: 0,
      usePagination: false,
      links: {
        first: '',
        last: '',
        next: '',
        prev: '',
        self: '',
      },
    },
  }
  sortColumn = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortByProp || '';
  sortDirection = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortDirection || 'ASC';
  offset = 0;
  itemsPerPage = 25;
  surveys: any[] = [];
  survey = {}
  formattedSurveys: NewTableData['data']= [];
  surveysData$ = this.surveyService.surveysData$;
  surveysSubscription = this.surveysData$.subscribe((data) => {
    this.isLoading = false;
    if (!data) {
      return;
    }

    // const { pagination, links } = data;

    // const paginationInfo: NewTableData['meta'] = {
    //   totalPages: pagination.totalPages,
    //   totalItems: pagination.totalItems,
    //   itemsPerPage: pagination.totalPerPage,
    //   curPage: pagination.curPage,
    //   usePagination: false,
    //   links,
    // }

    // this.tableData.meta = paginationInfo;

    this.surveys = data.rows;
    this.formatSurveys(this.surveys);
    this.tableData.data = this.formattedSurveys;
  });

  constructor(public dialog: MatDialog, private surveyService: SurveysService) {}

  ngOnInit(): void {
    this.searchForSurveys();
  }

  searchForSurveys() {
    this.isLoading = true;
    this.surveyService.getSurveys({
      limit: this.itemsPerPage,
      offset: this.offset,
      activeFlag: 1,
      filterString: this.searchText,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      includedAssociations: 'Courses',
    })
  }

  searchForInactiveSurveys() {
    this.isLoading = true;
    this.surveyService.getSurveys({
      limit: this.itemsPerPage,
      offset: this.offset,
      activeFlag: 0,
      filterString: this.searchText,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      includedAssociations: 'Courses',
    })
  }

  formatSurveys(surveys: any[]): typeof this.formattedSurveys {
    this.formattedSurveys = surveys?.map(survey => {
      const surveyData = {
        name: survey.name,
        id: survey.id,
        url: survey.url,
        activeFlag: survey.activeFlag,
        status: survey.activeFlag === 1 ? 'Active' : 'Inactive',
        addedByFirstName: survey.CreateUser.firstName,
        addedByLastName: survey.CreateUser.lastName,
        addedByEmail: survey.CreateUser.contactEmail,
      }

      const surveyCell: GeneralDataCell = {
        mainText: surveyData.name,
        mainFontStyle: 'normal',
      }

      const urlCell: HyperlinkCell = { url: surveyData.url }

      const addedByCell: AvatarDataCell = {
        cellType: 'avatarCell',
        firstName: surveyData.addedByFirstName,
        lastName: surveyData.addedByLastName,
        email: surveyData.addedByEmail,
        size: 'md', // Or 'sm','xs','lg','xl' – your choice
      };

      const statusCell: StatusDataCell = {
        statusDisplay: surveyData.status, statusKid: surveyData.status, statusType: 'active',
      }

      return {
        itemId: surveyData.id,
        columnData: [
          surveyCell, urlCell, addedByCell, statusCell,
        ],
      }
    });

    return this.formattedSurveys;
  }

  handleSearchChange = (event) => {
    this.searchText = event;
    if (this.mode === 'active') {
      this.searchForSurveys()
    } else {
      this.searchForInactiveSurveys()
    }
  }

  openNewSurveyModal = () => {
    const dialog = this.dialog.open(AddSurveyComponent, { disableClose: false, data: { survey: this.survey, mode: 'new' } })

    dialog.afterClosed().subscribe(() => {
      if (this.mode === 'active') {
        this.searchForSurveys()
      } else {
        this.searchForInactiveSurveys()
      }
    });
  }

  openSurveyModal = (survey, mode) => {
    const dialog = this.dialog.open(AddSurveyComponent, { disableClose: false, data: { survey: survey, mode: mode } })

    dialog.afterClosed().subscribe(() => {
      if (this.mode === 'active') {
        this.searchForSurveys()
      } else {
        this.searchForInactiveSurveys()
      }
    });
  }

  toggleViewInactive = () => {
    this.mode = this.mode === 'active' ? 'inactive' : 'active';
    if (this.mode === 'active') {
      this.searchForSurveys()
    } else {
      this.searchForInactiveSurveys()
    }
  }

  handleBurgerMenuClicked = (event) => {
    if (event.eventName === 'edit-survey') {
      const survey = this.surveys.find(survey => survey.id === event.itemId)
      this.openSurveyModal(survey, 'edit')
    }
    if (event.eventName === 'view-details') {
      const survey = this.surveys.find(survey => survey.id === event.itemId)
      this.openSurveyModal(survey, 'view')
    }
    if (event.eventName === 'remove-survey') {
      const survey = this.surveys.find(survey => survey.id === event.itemId)
      this.openSurveyModal(survey, 'remove')
    }
  }

  handleSortClicked = (event) => {
    this.sortColumn = event.columnName
    this.sortDirection = event.sortDirection
    if (this.mode === 'active') {
      this.searchForSurveys()
    } else {
      this.searchForInactiveSurveys()
    }
  }

}
